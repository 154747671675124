import { toast } from "react-toastify";
import { initialStateTypes } from "../types/paymentInitialState";
import * as yup from "yup";
import Bugsnag from "@bugsnag/js";

export const parseJwt = (token: string) => {
  try {
    const [, payloadBase64] = token.split(".");
    const decodedPayload = atob(payloadBase64);
    const result = decodedPayload.split(/[&?]/);
    let obj: any = {};
    result.forEach((item) => {
      if (item !== "") {
        const [key, value] = item.split("=");
        obj[key] = value;
      }
    });
    return obj;
  } catch (error: any) {
    notifyBugsnagError(error, {
      InValid: "parseJwt",
    });
    // console.error("Error parsing JWT:", error);
    return null;
  }
};

export const checkType = (type: string | undefined) => {
  let result;
  if (type === "qr_property") {
    result = "pid";
  }
  if (type === "qr_room") {
    result = "rid";
  }
  if (type === "qr_employee") {
    result = "eid";
  }
  if (type === "qr_service") {
    result = "sid";
  }
  return result ?? "";
};

/**
 * Calculate the organization comission
 * @param tipAmount
 */
const checkOrganizationComission = (tipAmount: number, orgData: any) => {
  // let tippedAmount = tipAmount;
  // let adminCharge = 0.49;
  // if (orgData && orgData?.uuid) {
  //   if (orgData?.top_tipper_fees) {
  //     adminCharge = orgData?.top_tipper_fees;
  //   } else {
  //     if (tippedAmount > 9.9 && tippedAmount < 50) {
  //       adminCharge = 0.99;
  //     } else if (tippedAmount > 49.99) {
  //       adminCharge = 1.5;
  //     }
  //   }
  // }
  // return adminCharge.toFixed(2);
  let adminCharge = orgData?.top_tipper_fees;
  return adminCharge?.toFixed(2);
};

export const calculateFee = (value: string, orgData: any): [string, string] => {
  if (value !== "" && +value > 0) {
    let tipAmount = parseFloat(value);
    let topTipperComission = checkOrganizationComission(tipAmount, orgData);
    let totalTipAmount = (
      (tipAmount + 0.3 + parseFloat(topTipperComission)) /
      0.971
    ).toFixed(2);
    let stripeCut: string | number =
      parseFloat(totalTipAmount) - (tipAmount + parseFloat(topTipperComission));
    stripeCut = stripeCut.toFixed(2);
    let totalApplicationFee = Number(topTipperComission) + Number(stripeCut);
    return [totalApplicationFee.toFixed(2), stripeCut];
  } else {
    return ["", ""];
  }
};

export function isValidEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function isValidUrl(url: string) {
  if (url && url?.length <= 50) {
    return true;
  } else {
    return false;
  }
}

export const guestyValidation = yup.object({
  first_name: yup.string().required("Manager Name is required."),
  name: yup.string().required("Organization Name is required."),
  email: yup
    .string()
    .email("Invalid email address.")
    .required("Email is required."),
  token: yup.string().required("Integration Token is required."),
  url_code: yup
    .string()
    .max(50, "URL Format must not exceed 50 characters.")
    .required("URL Abbreviation is required."),
  password: yup
    .string()
    .trim()
    .required(`Password is required.`)
    .max(20, "Maximum 20 characters.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
      "Minimum 8 Characters(1 upper & lower case, number and special character)."
    ),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf(
      [yup.ref("password"), ""],
      `Password and Confirm Password doesn't match. `
    )
    .required("Confirm Password is required."),
});

export const showToast = (message: string, type: "success" | "error") => {
  if (type === "success") {
    toast.dismiss();
    toast.success(message, { autoClose: 5000 });
  } else if (type === "error") {
    toast.dismiss();
    toast.error(message, { autoClose: false });
  }
};

export const removeInitialSpace = (value: string) => value.replace(/^\s+/g, "");

export const notifyBugsnagError = (
  error: Error | string,
  metadata?: Record<string, any>
) => {
  Bugsnag.notify(error, (event) => {
    if (metadata) {
      event.addMetadata("custom", metadata);
    }
  });
};
