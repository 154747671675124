import PaymentFooter from "../tippingUiSection/footer";

interface IProps {
  children: string | JSX.Element;
}

const TipperLayout = ({ children }: IProps) => {
  return (
    <div className="layout">
      {/* <PaymentHeader /> */}
      {children}
      {/* <PaymentFooter /> */}
    </div>
  );
};

export default TipperLayout;
