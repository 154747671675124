import not_found from "../../../assests/tipperUiSection/not-found.png";
// import thankyou_failed from "../../../assests/tipperUiSection/failed.png";
import TipperLayout from "../../../components/tipperUiSectionLayout";
import PaymentFooter from "../../../components/tippingUiSection/footer";
import PaymentHeader from "../../../components/tippingUiSection/header";
import styles from "./NotFound.module.scss";
const NotFound = () => {
  return (
    <>
      <TipperLayout>
        <div>
          <div className={styles.formContainer}>
            <div className={styles.tipperBottom}>
              <div className={styles.not_foundImg}>
                <img src={not_found} alt="" />
              </div>
              <div>
                <h3>Oops! We Couldn’t Find That Organization</h3>
                <p>
                  It looks like the organization you're looking for is either
                  inactive or doesn't exist.
                </p>
              </div>
            </div>
            <PaymentHeader />
          </div>
          <PaymentFooter />
        </div>
      </TipperLayout>
    </>
  );
};
export default NotFound;
