import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import noImage from "../../../assests/tipperUiSection/toptipper-logo.png";
import StripePayment from "../../../components/stripePayment";
import TipperLayout from "../../../components/tipperUiSectionLayout";
import { AppDispatch, RootState } from "../../../store/store";
import styles from "./orderDelievered.module.scss";
import PaymentHeader from "../../../components/tippingUiSection/header";
import { setPrevUrl } from "../../../store/slices/paymentSlices";
import PaymentFooter from "../../../components/tippingUiSection/footer";

const stripe = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const OrderDelievered = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const { data } = useSelector(
    (store: RootState) => store.paymentSlices.postTipAmount
  );
  const { media, name, org_name, type, property_name } = useSelector(
    (store: RootState) => store.paymentSlices.data
  );
  const { homeUrl, prevUrl } = useSelector(
    (store: RootState) => store.paymentSlices
  );
  const options: StripeElementsOptions = {
    // passing the client secret obtained from the server
    clientSecret: data?.clientSecret || "",
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          fontWeight: "700",
          color: "#6A6D7C",
          fontSize: "12px",
        },
        ".Input::placeholder": {
          color: "#A9B0BF",
        },
      },
    },
  };
  useEffect(() => {
    setIsStripeLoading(true);
    setTimeout(() => {
      setIsStripeLoading(false);
    }, 2000);
  }, []);
  // useEffect(() => {
  //   if (!data?.clientSecret) {
  //     window.location.href = `${process.env.REACT_APP_LANDING_URL}`;
  //   }
  // }, []);

  useEffect(() => {
    if (prevUrl === "/payment-completed") {
      navigate(`${homeUrl}`);
      dispatch(setPrevUrl(""));
    }
  }, []);


  useEffect(() => {
    if (!data?.clientSecret) {
      if (location.key !== 'default') {
        // If there's a previous history, go back
        navigate(-1);
      } else {
        // Otherwise, navigate to the main page
        navigate('/');
      }
    }
  }, [navigate, location]);


  return (
    data?.clientSecret ?
      <TipperLayout>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 30,
          }}
        >
          <div className={styles.orderContainer}>
            <div className={styles.tipperContainer}>
              <div className={styles.tipperImg}>
                <div className={styles.tipperImg} style={{}}>
                  {false ? (
                    <Skeleton height={100} width={100} />
                  ) : (
                    <div
                      className={styles.circle}
                      style={{ backgroundColor: data?.theme_color }}
                    >
                      <img src={media ?? noImage} alt="" />
                    </div>
                  )}
                </div>
                <h5
                  className="mt-0"
                  style={{
                    color: "black",
                    fontWeight: 600,
                    wordBreak: "break-word",
                    paddingRight: "5px",
                  }}
                >
                  {org_name && type == "1"
                    ? org_name
                    : property_name
                      ? property_name
                      : ""}
                </h5>
              </div>
              <div className={styles.tipperBottom}>
                {false ? (
                  <Skeleton width={150} />
                ) : (
                  <>
                    {/* <h5 className="mt-0">
                    {type === "qr_organization_details" ||
                    type === "qr_property"
                      ? org_name
                      : property_name}
                  </h5> */}
                    {/* <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {name && name }{" "}
                  </p> */}
                  </>
                )}
                <div className={styles.suggestedAmount}>
                  {false ? (
                    <div
                      style={{
                        position: "relative",
                        backgroundColor: isStripeLoading ? "#EFEFEF" : "white",
                      }}
                    >
                      <Skeleton height={99} width={340} className="suggest" />
                      <div className={styles.skel}>
                        <Skeleton height={50} width={50} />
                        <Skeleton height={50} width={50} />
                        <Skeleton height={50} width={50} />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.suggestCards}>
                      <div className={styles.chargeAmount}>
                        {" "}
                        <p>Tip Amount</p>
                        <span>
                          {data?.tip_amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          }) ?? 0}
                        </span>
                      </div>
                      <div className={styles.chargeAmount}>
                        {" "}
                        <p>Application Fee</p>
                        <span>
                          {data?.application_fee?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          }) ?? 0}
                        </span>
                      </div>
                      <div className={styles.chargeAmount}>
                        {" "}
                        <p>Charged Amount</p>
                        <span className={styles.end}>
                          {(
                            (data?.application_fee ?? 0) + (data?.tip_amount ?? 0)
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {
                  data?.clientSecret &&

                  <Elements stripe={stripe} options={options}>
                    {<StripePayment />}
                  </Elements>
                }
              </div>
              <PaymentHeader />
            </div>
          </div>
          <PaymentFooter />
        </div>
      </TipperLayout> : null
  );
};
export default OrderDelievered;
