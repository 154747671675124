import noImage from "../../assests/tipperUiSection/toptipper-logo.png";
import styles from "../../pages/tippingUiSection/paymentDone/paymentDone.module.scss";
import TipperLayout from "../tipperUiSectionLayout";
import PaymentHeader from "../tippingUiSection/header";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../store/store";
import {
  facebook,
  google,
  trip,
  yelp,
} from "../../assests/tipperUiSection/Icons/icons";
import PaymentFooter from "../tippingUiSection/footer";

type review = {
  isGoogleReview?: boolean;
  isTripAdvisor?: boolean;
  is_yelp?: boolean;
  is_facebook?: boolean;
};

const PaymentDoneDynamic = ({
  isGoogleReview,
  isTripAdvisor,
  is_yelp,
  is_facebook,
}: review) => {
  const {
    media,
    theme_color,
    google_site_url,
    trip_advisor_url,
    yelp_url,
    facebook_url,
  } = useSelector((store: RootState) => store.paymentSlices.data);

  const ifHttps = (url: string) => {
    // check if https or http is available
    if (!url.includes("https") || !url.includes("http")) {
      // check if com is available or not available
      if (!url.includes("com")) {
        return "https://" + url + ".com";
      } else {
        return "https://" + url;
      }
    }
    // if valid url then return as it is
    else {
      return url;
    }
  };

  return (
    <TipperLayout>
      <div
        style={
          {
            // flex: 1,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // marginTop: 30,
          }
        }
      >
        <div className={styles.thankyou_container}>
          <div className={styles.thankyou_wrapper}>
            <div
              className={styles.circle}
              style={{ backgroundColor: theme_color }}
            >
              <img src={media ?? noImage} alt="" className={styles.img} />
            </div>
            <div
              className={styles.text}
              style={{
                marginTop: 10,
              }}
            >
              <h5>Thanks for tipping!</h5>
              <p>Our service workers appreciate you.</p>
            </div>
            {(isGoogleReview || isTripAdvisor || is_yelp || is_facebook) && (
              <div className={styles.thankyou_card}>
                {/* <p>
                  Make this service worker's day by leaving a review on
                  {isGoogleReview && isTripAdvisor
                    ? " below platforms."
                    : isGoogleReview
                    ? " Google"
                    : isTripAdvisor
                    ? " TripAdvisor!"
                    : ""}
                </p> */}
                <div
                  className="d-flex flex-column justify-content-center align-items-center gap-4"
                  style={
                    {
                      // padding: "20px 0px",
                    }
                  }
                >
                  {" "}
                  {isGoogleReview && (
                    <Link
                      to={ifHttps(`${google_site_url}`)}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                      }}
                      className={styles.links}
                    >
                      {google} Leave a Google review
                      {/* <img src={google} alt="" /> */}
                    </Link>
                  )}
                  {/* {isGoogleReview && isTripAdvisor && (
                    <div
                      style={{
                        width: 40,
                        // height: 40,
                        borderBottom: "1px solid gray",
                      }}
                      className=" d-inline-block m-auto "
                    />
                  )} */}
                  {isTripAdvisor && (
                    <Link
                      to={ifHttps(`${trip_advisor_url}`)}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                      }}
                      className={styles.links}
                    >
                      {trip} Leave a TripAdvisor review{" "}
                    </Link>
                  )}
                  {is_yelp && (
                    <Link
                      to={ifHttps(`${yelp_url}`)}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                      }}
                      className={styles.links}
                    >
                      {yelp} Leave a Yelp review{" "}
                    </Link>
                  )}
                  {is_facebook && (
                    <Link
                      to={ifHttps(`${facebook_url}`)}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                      }}
                      className={styles.links}
                    >
                      {facebook} Leave a Facebook review{" "}
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              padding: "0px 15px",
            }}
          ></div>
          <PaymentHeader />
        </div>
        <PaymentFooter />
      </div>
    </TipperLayout>
  );
};

export default PaymentDoneDynamic;
