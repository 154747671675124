import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import noImage from "../../../src/assests/tipperUiSection/toptipper-logo.png";
import { eyeIcon } from "../../assests/tipperUiSection/Icons/icons";
import oops from "../../assests/tipperUiSection/oops.svg";
import ReactSkeleton from "../../components/reactSkeleton";
import ThemeButton from "../../components/theme/themeButton";
import TipperLayout from "../../components/tipperUiSectionLayout";
import ConfirmationModal from "../../components/tipperUiSectionLayout/modal/ConfirmationModal";
import PaymentFooter from "../../components/tippingUiSection/footer";
import PaymentHeader from "../../components/tippingUiSection/header";
import {
  getOldTippingUrlApi,
  getPaymentData,
  postTipAmountApi,
  setHomeUrl,
  setPrevUrl,
} from "../../store/slices/paymentSlices";
import { AppDispatch, RootState } from "../../store/store";
import { calculateFee, notifyBugsnagError } from "../../utils/functions";
import styles from "./tipperPayment.module.scss";
const TippingPayment = () => {
  const [applicationFee, setApplicationFee] = useState<number | string>(0);
  const [intentData, setIntentData] = useState({ type: "", uuid: "" });
  const [stripeFee, setStripeFee] = useState<number | string>(0);
  const [fieldError, setFieldError] = useState("");
  const [postError, setPostError] = useState("");
  const [tipAmount, setTipAmount] = useState<string>("");
  const [isActive, setIsActive] = useState<number | null>(null);
  const [showSubmitModal, setSubmitModal] = useState(false);
  const [isOrgExist, setIsOrgExist] = useState(true);
  const handleSubmitModal = () => setSubmitModal(!showSubmitModal);
  const { token1, token2 }: any = useParams();
  const { pathname } = useLocation();
  const url = window.location.href;
  const navigate = useNavigate();
  const { data, isGetLoading, postTipAmount } = useSelector(
    (store: RootState) => store.paymentSlices
  );
  const dispatch = useDispatch<AppDispatch>();

  let getLastParam = pathname.split("/");
  getLastParam = getLastParam.filter((item) => item !== "");
  const isCopy = getLastParam[getLastParam.length - 1] === "c";

  /**
   * handle submit data to make payment
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const regex = /^\d{0,6}(?:\.\d*)?$/;
    if (
      tipAmount !== "" &&
      +tipAmount > 0 &&
      fieldError?.length === 0 &&
      regex.test(tipAmount)
    ) {
      let jwtData: string[] | null = null;
      if (getLastParam.includes("c") || getLastParam.length === 1) {
        const decodedToken: any = atob(token1);
        // Convert from UTF-8 to string
        const utf8Decoder = new TextDecoder();
        const utf8Token = utf8Decoder.decode(
          new Uint8Array([...decodedToken].map((char) => char.charCodeAt(0)))
        );
        // Split token
        jwtData = utf8Token.split("/");
      }
      dispatch(
        postTipAmountApi({
          stripe_fee: stripeFee,
          tip_amount: tipAmount,
          application_fee: applicationFee,
          type: jwtData ? jwtData[1] : intentData.type,
          uuid: jwtData ? jwtData[0] : intentData.uuid,
        })
      )
        .unwrap()
        .then((res: any) => {
          if (res?.status === 0 || res?.status === 500) {
            setPostError(res?.data?.message);
            handleSubmitModal();
          } else {
            setApplicationFee(0);
            setStripeFee(0);
            setTipAmount("");
            navigate("/payment-confirm");
          }
        });
      setFieldError("");
    } else {
      if (fieldError?.length > 0) {
        setFieldError(fieldError);
      } else {
        if (!regex.test(tipAmount)) {
          setFieldError("Tip Amount should not be greater than 6 digits.");
        } else if (+tipAmount <= 0 && tipAmount !== "") {
          setFieldError("Tip Amount should be greater than 0.");
        } else {
          setFieldError("Please enter tip amount.");
        }
      }
    }
  };

  /**
   *
   * handle onchange get input value when user enter amount
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Check if the input value matches the regex
    setTipAmount(inputValue); // Set the tipAmount with two decimal places
    setFieldError("");
    setIsActive(null);
  };

  /**
   * @use_effect to calculate fee when @tipamount state updates
   */
  useEffect(() => {
    const [application_fee, stripe_fee] = calculateFee(tipAmount, data);
    setApplicationFee(application_fee ?? 0);
    setStripeFee(stripe_fee ?? 0);
  }, [tipAmount]);

  /**
   * @use_effect set homeurl
   */
  useEffect(() => {
    dispatch(setHomeUrl(pathname));
    dispatch(setPrevUrl(""));
  }, [dispatch, pathname]);

  function isBase64(str: string) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
  /**
   * @use_effect get data of current scanned user from token
   */
  useEffect(() => {
    if (
      !(getLastParam.includes("c") || getLastParam.length === 1) &&
      getLastParam.length >= 2
    ) {
      let orgIdentifier = null;
      let propertyId = null;
      let serviceIdentifier = null;
      if (getLastParam.includes("org")) {
        orgIdentifier = token1;
      } else if (getLastParam.includes("guesty")) {
        orgIdentifier = token1;
        propertyId = token2;
      } else if (
        !getLastParam.includes("guesty") &&
        !getLastParam.includes("org") &&
        getLastParam.length == 2
      ) {
        orgIdentifier = token1;
        serviceIdentifier = token2;
      }
      const payload: any = {
        data: {
          org_identifier: orgIdentifier,
          service_identifier: serviceIdentifier,
          property_uuid: propertyId,
          url: url,
        },
      };
      (async () => {
        const getOldTipping = await dispatch(getOldTippingUrlApi(payload));
        const res = await getOldTipping?.payload;
        if (res?.status === 1) {
          const intent_data = {
            type: `${res?.data?.type}`,
            uuid: `${res?.data?.uuid}`,
          };
          setIntentData(intent_data);
          setIsOrgExist(true);
        } else {
          // Show toast error for all cases except when tipping is explicitly disabled (false)
          if (res?.data?.is_tipping_enabled == false) {
            setIsOrgExist(false);
          } else if (res?.data?.hasOwnProperty("error")) {
            if (res?.data?.error === "not exist") {
              navigate("/not-found");
            }
          } else {
            toast.error(res?.message, {
              autoClose: false,
            });
            setIsOrgExist(false);
          }
        }
      })();
    } else {
      if (getLastParam.includes("c") || getLastParam.length === 1) {
        try {
          // Decode the token
          const decodedToken: any = atob(token1);

          // Convert from UTF-8 to string
          const utf8Decoder = new TextDecoder();
          const utf8Token = utf8Decoder.decode(
            new Uint8Array([...decodedToken].map((char) => char.charCodeAt(0)))
          );

          // Split token and create paramData
          const jwtData = utf8Token.split("/");
          const paramData = {
            type: jwtData[1],
            uuid: jwtData[0],
            url: url,
          };

          // Dispatch action with paramData
          dispatch(getPaymentData({ data: paramData, isCopy }))
            .unwrap()
            .then((res: any) => {
              if (
                res?.status == 0 &&
                res?.data.hasOwnProperty("error")
              ) {
                if (res?.data?.error === "not exist") {
                  navigate("/not-found");
                } else {
                  if (res?.status == 0) {
                    toast.error(res?.message);
                  }
                }
              }
            })
            .catch((err: any) => {
              notifyBugsnagError(err, {
                api: "getPaymentData",
              });
            });
        } catch (error: any) {
          navigate("/not-found");
          setIsOrgExist(false);
          // toast.error("Organization does not exists.", {
          //   autoClose: false,
          // });
          // notifyBugsnagError(error, {
          //   api: "getPaymentData",
          // });
        }
      }
    }
  }, []);

  const decodedToken: any = "";
  // Convert from UTF-8 to string
  const utf8Decoder = new TextDecoder();
  const utf8Token = utf8Decoder.decode(
    new Uint8Array([...decodedToken].map((char) => char.charCodeAt(0)))
  );

  // Split token
  const jwtData = utf8Token.split("/");
  useEffect(() => {
    if (pathname.length <= 1) {
      window.location.href = `${process.env.REACT_APP_LANDING_URL}`;
    }
  }, []);
  return (
    <>
      <TipperLayout>
        <div
          style={
            {
              // flex: 1,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // marginTop: 30,
            }
          }
        >
          {isGetLoading ? (
            <ReactSkeleton />
          ) : (
            <div className={styles.formContainer}>
              <form onSubmit={handleSubmit} className={styles.tipperContainer}>
                <div className={styles.tipperBottom}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <div
                      className={styles.circle}
                      style={{
                        backgroundColor: data?.theme_color,
                      }}
                    >
                      <img src={data?.media ?? noImage} alt="" />
                    </div>
                  </div>
                  <h5 className="">
                    {data?.org_name &&
                      (data?.type == "1" || intentData.type == "1")
                      ? data?.org_name
                      : data?.property_name
                        ? data?.property_name
                        : ""}
                  </h5>

                  <p className={styles.text}>
                    “Gratitude is the sign of noble souls.” ~Aesop
                  </p>
                  {data?.name && data?.type != "2" && (
                    <div className={styles.user}>
                      <span>
                        {/* {user} */}
                        {data?.name}
                      </span>
                    </div>
                  )}
                  {(!!data?.suggested_amount_1 ||
                    !!data?.suggested_amount_2 ||
                    !!data?.suggested_amount_3) && (
                      <div className={styles.suggestedAmount}>
                        <h6>Tip amount ($)</h6>
                        <div className={styles.suggestCards}>
                          {!!data?.suggested_amount_1 && (
                            <p
                              className={
                                isActive === 1 &&
                                  data?.suggested_amount_1 === +tipAmount
                                  ? styles.differColor
                                  : ""
                              }
                              onClick={() => {
                                setFieldError("");
                                setTipAmount(`${data?.suggested_amount_1}`);
                                setIsActive(1);
                              }}
                            >
                              {
                                /* {data?.suggested_amount_1.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })} */
                                data?.suggested_amount_1
                              }
                            </p>
                          )}
                          {!!data?.suggested_amount_2 && (
                            <p
                              className={
                                isActive === 2 &&
                                  data?.suggested_amount_2 === +tipAmount
                                  ? styles.differColor
                                  : ""
                              }
                              onClick={() => {
                                setFieldError("");
                                setTipAmount(`${data?.suggested_amount_2}`);
                                setIsActive(2);
                              }}
                            >
                              {
                                /* {data?.suggested_amount_2.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })} */
                                data?.suggested_amount_2
                              }
                            </p>
                          )}
                          {!!data?.suggested_amount_3 && (
                            <p
                              className={
                                isActive === 3 &&
                                  data?.suggested_amount_3 === +tipAmount
                                  ? styles.differColor
                                  : ""
                              }
                              onClick={() => {
                                setFieldError("");
                                setTipAmount(`${data?.suggested_amount_3}`);
                                setIsActive(3);
                              }}
                            >
                              {
                                /* {data?.suggested_amount_3.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })} */
                                data?.suggested_amount_3
                              }
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  <div className={styles.tipperRightForm}>
                    {" "}
                    <label htmlFor="">
                      {!!data?.suggested_amount_1 ||
                        !!data?.suggested_amount_2 ||
                        !!data?.suggested_amount_3
                        ? "Custom amount ($)"
                        : "Tip amount ($)"}
                    </label>
                    <br />
                    <div className={styles.search}>
                      {/* {dollar} */}
                      <NumericFormat
                        allowNegative={false}
                        style={{
                          cursor:
                            data?.is_tipping_enabled !== undefined
                              ? "not-allowed"
                              : "text",
                        }}
                        disabled={
                          data?.is_tipping_enabled !== undefined || !isOrgExist
                        }
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        placeholder="Enter Amount"
                        // maxLength={9}
                        onChange={(e) => handleChange(e)}
                        value={tipAmount}
                      />
                    </div>
                    {fieldError && (
                      <span className={styles.error}>{fieldError}</span>
                    )}
                    {tipAmount && +applicationFee >= 0 && (
                      <p>
                        {eyeIcon} ${(+applicationFee).toFixed(2)} application
                        fee will be added to the transaction total.
                      </p>
                    )}
                  </div>
                  {data?.is_tipping_enabled !== undefined ? (
                    <div className="tippingEnabled">
                      {data?.message ? data?.message : ""}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        // marginTop: "14px",
                        padding: "0px 18px",
                      }}
                    >
                      <ThemeButton
                        type="submit"
                        loading={postTipAmount.isPostDataLoading}
                        disabled={!isOrgExist}
                      >
                        Continue
                      </ThemeButton>
                      <ConfirmationModal
                        handleToggle={handleSubmitModal}
                        icon={oops}
                        title={"we are not quite ready to receive tips here."}
                        desc={postError ?? "You have not been charged."}
                        show={showSubmitModal}
                      />
                    </div>
                  )}
                </div>
              </form>
              <PaymentHeader />
            </div>
          )}
          <PaymentFooter />
        </div>
      </TipperLayout>
    </>
  );
};
export default TippingPayment;
