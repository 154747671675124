import { useEffect, useState } from "react";
import thankyoulogo from "../../../assests/tipperUiSection/thankyou-logo.png";
import thankyou_success from "../../../assests/tipperUiSection/thankyou-success.png";
import styles from "./redirectPages.module.scss";
import { Link } from "react-router-dom";
const Success = () => {
  const [isWebView, setIsWebView] = useState(window.innerWidth >= 1124);

  const checkIfWebView = () => {
    setIsWebView(window.innerWidth >= 1124);
  };

  useEffect(() => {
    window.addEventListener("resize", checkIfWebView);

    return () => {
      window.removeEventListener("resize", checkIfWebView);
    };
  }, []);
  return (
    <>
      <div className={styles.thankyouWrapper}>
        <div className={styles.thankyouHeader}>
          <img src={thankyoulogo} alt="" />
        </div>
        <div className={styles.thankyouContent}>
          <section className={styles.contentContainer}>
            <div className={styles.thankyouLeft}>
              <h3>Payment Setup Complete</h3>
              <p className={styles.breakText}>
                Thank you for completing the Stripe Payment Setup. You will
                receive an{isWebView ? <br /> : ""} email notifying you that the
                information has been processed and verified. (This is usually
                pretty quick).
              </p>
              <p>
                If you have any issues, please don’t hesitate to email us at:
                <br />
                <Link
                  to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  className="text-decoration-none"
                >
                  {" "}
                  <span>support@toptipper.com</span>
                </Link>
              </p>
              <Link
                to={`${process.env.REACT_APP_DASHBOARD_URL}`}
                target="_blank"
              >
                <button className={styles.btn}>Go to My Dashboard</button>
              </Link>
            </div>
            <div className={styles.thankyouRight}>
              <img src={thankyou_success} alt="" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Success;
