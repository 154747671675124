import { tipperHeader } from "../../../assests/tipperUiSection/Icons/icons";
import styles from "./header.module.scss";

const PaymentHeader = () => {
  return (
    <div
      className={styles.navWrapper}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: 80,
        // marginTop: "176px",
        // paddingBottom: 10,
      }}
    >
      <p className="d-flex  gap-2  align-items-baseline">
        Powered by{""}
        <span
          className={styles.topTipper}
          style={{
            display: "inline-block",
            marginBottom: 1,
          }}
        >
          {tipperHeader}
        </span>
        {""}
        <span
          style={{
            color: "gray",
          }}
        >
          &{" "}
        </span>
        <span className={styles.stripe}>stripe</span>{" "}
      </p>
    </div>
  );
};

export default PaymentHeader;
