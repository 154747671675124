import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialStateTypes } from "../../types/paymentInitialState";
import { axiosInstance } from "../../utils/instance";
import { notifyBugsnagError } from "../../utils/functions";

/**
 * @getPaymentData action to get data from token that we are getting from url
 */
export const getPaymentData = createAsyncThunk(
  "payment-data/getPaymentData",
  async (param: { data: { type: string; uuid: string }; isCopy: boolean }) => {
    try {
      const res = await axiosInstance.post(
        `/common/get-qr-code-details${param.isCopy ? "?is_copy=1" : ""}`,
        param.data
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      return error?.response;
    }
  }
);

/**
 * @postTipAmount action to post tip amount and application fee with stripe fee
 */
export const postTipAmountApi = createAsyncThunk(
  "payment-data/postTipAmount",
  async (param: {
    stripe_fee: string | number;
    tip_amount: string | number;
    application_fee: number | string;
    type: string;
    uuid: string;
  }) => {
    try {
      const res = await axiosInstance.post("/user/set-up-intents", param);
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "postTipAmountApi",
      });
      return error?.response;
    }
  }
);

/**
 * @sendTip api to send tip
 */
export const sendTipApi = createAsyncThunk(
  "payment-data/sendTipApi",
  async (param: {
    tip_amount: null | number;
    application_fee: null | number;
    stripe_fee: null | number;
    type: string;
    uuid: string;
    email?: string | null;
    status: null | number;
    stripe_transaction_id: string | null;
  }) => {
    try {
      const res = await axiosInstance.post("/user/send-tip", param);
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "sendTipApi",
      });
      return error.response;
    }
  }
);

/**
 * @guestyIntegration api
 */
export const guestyIntegrationApi = createAsyncThunk(
  "payment-data/guestyIntegrationApi",
  async (param: {
    first_name: string;
    name: string;
    email: string | null;
    url_code: string;
    token: string;
    password: string;
  }) => {
    try {
      const res = await axiosInstance.post("/organization/add-guesty", param);
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "guestyIntegrationApi",
      });
      return error.message;
    }
  }
);

/**
 * @validateNameApi api
 */
export const validateNameApi = createAsyncThunk(
  "payment-data/validateNameApi",
  async (param: { name: string; type: number | null }) => {
    try {
      const res = await axiosInstance.post("/common/validate-name", param);
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "validateNameApi",
      });
      return error.message;
    }
  }
);

/**
 * @validateEmailApi api
 */
export const validateEmailApi = createAsyncThunk(
  "payment-data/validateEmailApi",
  async (param: { email: string }) => {
    try {
      const res = await axiosInstance.post("/common/validate-email", param);
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "validateEmailApi",
      });
      return error.message;
    }
  }
);

/**
 * @validateIdentifierApi api
 */
export const validateIdentifierApi = createAsyncThunk(
  "payment-data/validateIdentifierApi",
  async (param: { type: number | null; url_code: string }) => {
    try {
      const res = await axiosInstance.post(
        "/common/validate-identifier",
        param
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      notifyBugsnagError(error, {
        api: "validateIdentifierApi",
      });
      return error.message;
    }
  }
);

export const getOldTippingUrlApi = createAsyncThunk(
  "payment-data/getOldTippingUrl",
  async (param: {
    data: {
      org_identifier: string;
      service_identifier: string;
      property_uuid: string;
    };
  }) => {
    const res = await axiosInstance.post(
      `/common/handle-old-tipping-urls`,
      param.data
    );
    const data = await res.data;
    return data;
  }
);

const initialState: initialStateTypes = {
  data: {
    property_name: "",
    type: "",
    google_site_url: null,
    trip_advisor_url: null,
    yelp_url: null,
    facebook_url: null,
    is_tipping_enabled: false,
    media: "",
    name: "",
    org_name: "",
    suggested_amount_1: null,
    suggested_amount_2: null,
    suggested_amount_3: null,
    top_tipper_fees: null,
    uuid: "",
    message: "",
    theme_color: "",
  },
  isGetLoading: false,
  postTipAmount: {
    data: {
      clientSecret: "",
      paymentIntent: "",
      application_fee: null,
      stripe_fee: null,
      tip_amount: null,
      type: "",
      uuid: "",
      theme_color: "",
    },
    isPostDataLoading: false,
  },
  sendTipLoading: false,
  homeUrl: "",
  prevUrl: "",
};

export const paymentSlice = createSlice({
  name: "payment-data",
  initialState,
  reducers: {
    setHomeUrl(state, { payload }) {
      state.homeUrl = payload;
    },
    setPrevUrl(state, { payload }) {
      state.prevUrl = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentData.pending, (state) => {
        state.isGetLoading = true;
      })
      .addCase(getPaymentData.fulfilled, (state, { payload }) => {
        state.data = payload?.data || {};
        state.data.message = payload?.message || "";
        state.isGetLoading = false;
      })
      .addCase(getPaymentData.rejected, (state) => {
        state.isGetLoading = false;
      })

      .addCase(getOldTippingUrlApi.pending, (state) => {
        state.isGetLoading = true;
      })
      .addCase(getOldTippingUrlApi.fulfilled, (state, { payload }) => {
        state.data = payload?.data || {};
        state.data.message = payload.message || "";

        // state.data.message = payload?.data?.message;
        state.isGetLoading = false;
      })
      .addCase(getOldTippingUrlApi.rejected, (state) => {
        state.isGetLoading = false;
      })

      .addCase(postTipAmountApi.pending, (state) => {
        state.postTipAmount.isPostDataLoading = true;
      })
      .addCase(postTipAmountApi.fulfilled, (state, { payload }) => {
        state.postTipAmount.isPostDataLoading = false;
        state.postTipAmount.data = payload.data;
      })
      .addCase(postTipAmountApi.rejected, (state, { payload }) => {
        state.postTipAmount.isPostDataLoading = false;
      })
      .addCase(sendTipApi.pending, (state) => {
        state.sendTipLoading = true;
      })
      .addCase(sendTipApi.fulfilled, (state) => {
        state.sendTipLoading = false;
      })
      .addCase(sendTipApi.rejected, (state) => {
        state.sendTipLoading = false;
      });
  },
});

export default paymentSlice.reducer;
export const { setHomeUrl, setPrevUrl } = paymentSlice.actions;
