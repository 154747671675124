import React, { useEffect } from "react";
import PaymentDoneDynamic from "../../../components/paymentDone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useLocation, useNavigate } from "react-router";
import { setPrevUrl } from "../../../store/slices/paymentSlices";

const PaymentDone = () => {
  const { google_site_url, trip_advisor_url, uuid ,yelp_url,facebook_url} = useSelector(
    (store: RootState) => store.paymentSlices.data
  );
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  useEffect(() => {
    if (!uuid) {
      navigate(-2);
    }
  }, []);

  useEffect(() => {
    dispatch(setPrevUrl(location.pathname));
  }, [location, dispatch]);
  return (
    <>
      <PaymentDoneDynamic
        isGoogleReview={!!google_site_url}
        isTripAdvisor={!!trip_advisor_url}
        is_yelp ={!!yelp_url}
        is_facebook={!!facebook_url}
      />
    </>
  );
};

export default PaymentDone;
