import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./Skeleton.module.scss";

const ReactSkeleton = () => {
  return (
    <div className={styles.formContainer}>
      <div className={styles.tipperContainer}>
        <div className={styles.tipperBottom}>
          {/* Skeleton for image */}
          <Skeleton height={100} width={100} />
          {/* Skeleton for organization name */}
          <Skeleton width={150} />
          {/* Skeleton for organization description */}
          <Skeleton width={350} className="desc" />
          {/* Skeleton for suggested tip amounts */}
          <div style={{ position: "relative", margin: "auto" }}>
            <Skeleton height={120} width={400} className="suggestedAmount" />
            <div
              style={{ position: "absolute", top: "10px", left: "130px" }}
              className="suggesstOne"
            >
              <Skeleton width={180} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                position: "absolute",
                top: "40px",
                left: "120px",
                margin: "auto",
              }}
              className="suggesstCol"
            >
              <Skeleton height={50} width={50} />
              <Skeleton height={50} width={50} />
              <Skeleton height={50} width={50} />
            </div>
          </div>
          <div className={styles.tipperRightForm}>
            {/* Skeleton for tip amount input */}
            <Skeleton width={150} />
            {/* Skeleton for input box */}
            <Skeleton width={350} height={50} className="inputBox" />
            {/* Skeleton for application fee amount */}
            <Skeleton width={300} />
            {/** Skeleton for continue button */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton width={150} height={30} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactSkeleton;
