import { Col, Container, Row } from "react-bootstrap";
import {
  getHelpIcon,
  sayHi,
} from "../../../assests/tipperUiSection/Icons/icons";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
const PaymentFooter = () => {
  return (
    <Container fluid>
      <div className={styles.footer}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Col xs={12} className="p-0 mb-4">
          <hr />{" "}
        </Col> */}
          <Col xs={12} style={{}} className={styles.footerWrapper}>
            <Link
              to={"https://toptipper.on.spiceworks.com/portal"}
              target="_blank"
              className="text-decoration-none"
            >
              {" "}
              <span className={styles.getHelp}>{getHelpIcon} Get Help</span>
            </Link>
            <Link
              to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              className="text-decoration-none"
            >
              <span className={styles.sayHi}>Say Hi {sayHi}</span>
            </Link>
          </Col>
        </Row>
      </div>

      {/* <Row>
        <Col>
          <div className={styles.footerRight}>
            {" "}
            <h6>Technical Support / Customer Service</h6>
            <div className={styles.footertext}>
              <Link
                to={"https://toptipper.on.spiceworks.com/portal"}
                target="_blank"
                className="text-decoration-none"
              >
                <p className={styles.ticket}>{email} Create a support ticket</p>
              </Link>
              <Link
                to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                className="text-decoration-none"
              >
                <p>
                  {email} {process.env.REACT_APP_SUPPORT_EMAIL}
                </p>
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="p-0">
          <div className={styles.bottomFooter}>
            <p>Copyright © 2021. All Right Reserved. </p>
            <div className={styles.bottomRight}>
              <Link
                to={
                  "https://app.termly.io/document/privacy-policy/d1193f10-5288-4f0b-a8bf-df5817997e8c"
                }
                target="_blank"
                className="text-decoration-none"
              >
                <p>Privacy policy</p>
              </Link>
              <Link
                to={
                  "https://toptipper.s3.amazonaws.com/TermsofServiceSept2022.pdf"
                }
                target="_blank"
                className="text-decoration-none"
              >
                <p>Terms and Conditions</p>
              </Link>
              <Link
                to={
                  "https://app.termly.io/document/disclaimer/1b516235-ddcd-463c-87d0-63be9b4564f1"
                }
                target="_blank"
                className="text-decoration-none"
              >
                <p>Disclaimer</p>
              </Link>
            </div>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
};

export default PaymentFooter;
