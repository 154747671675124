import { Modal } from "react-bootstrap";
import ThemeButton from "../../theme/themeButton";
import styles from "./confirmationModal.module.scss";
interface IProps {
  confirm?: (id: any) => void;
  handleToggle: () => void;
  title: string;
  show?: boolean;
  size?: any;
  loading?: boolean;
  actionTitle?: string;
  desc?: string;
  icon: string;
  color?: string;
}
const ConfirmationModal = ({
  confirm,
  handleToggle,
  title,
  show,
  size = "lg",
  loading,
  actionTitle = "Oops!",
  desc,
  color,
  icon,
}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size={size}
      className={styles.confirmationModal}
      centered
    >
      <Modal.Body>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>
            <span className={styles.icn}>
              <img src={icon} alt="Delete " />
            </span>
            <h2>{actionTitle}</h2>
            <h4>{title}</h4>
            <p
              style={{
                color,
              }}
            >
              {desc}
            </p>
          </div>
          <Modal.Footer className={styles.FooterModal}>
            <ThemeButton
              // variant="secondary"
              // className="primary  commonBtn"
              onClick={handleToggle}
            >
              Ok
            </ThemeButton>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
