// src/bugsnag.tsx
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// Type for the ErrorBoundary component
type ErrorBoundaryType = React.ComponentType<{ children?: React.ReactNode }>;

// Initialize Bugsnag with your API key
Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_KEY}`,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ["error", "log", "navigation", "request", "user"],
  // enabledReleaseStages: [`${process.env.REACT_APP_BUGSNAG_RELEASE_STAGE}`],
  // featureFlags: [
  //   { name: "Checkout button color", variant: "Blue" },
  //   { name: "Special offer", variant: "Free Coffee" },
  //   { name: "New checkout flow" },
  // ],
  // onError: function (event) {
  //   event.setUser("3", "sahil@yopmail.com", "Bugs Nag");
  // },
});

// Get the ErrorBoundary component from Bugsnag's React plugin
const bugsnagReactPlugin = Bugsnag.getPlugin("react");
if (!bugsnagReactPlugin) {
  throw new Error("Bugsnag React plugin not found");
}

const ErrorBoundary: ErrorBoundaryType =
  bugsnagReactPlugin.createErrorBoundary(React);

export default ErrorBoundary;
