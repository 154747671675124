import { configureStore } from "@reduxjs/toolkit";
import paymentSlices from "./slices/paymentSlices";

const store = configureStore({
  reducer: {
    paymentSlices,
  },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
