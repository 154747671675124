import { Button, ButtonProps } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import styles from "./theme.module.scss";

interface IProps extends ButtonProps {
  loading?: boolean;
}

const ThemeButton: React.FC<IProps> = ({ loading, ...rest }) => {
  return (
    <Button
      disabled={loading}
      type="button"
      {...rest}
      className={` ${styles.custom_btn} ${loading ? "loading" : ""} ${
        rest.className ?? ""
      }`}
    >
      {loading ? (
        <span
          className="loader"
          style={{
            display: "flex",
            height:40
          }}
        >
          <RotatingLines visible={true} strokeColor="#fff" width={'25px'}/>
        </span>
      ) : (
        rest.children
      )}
    </Button>
  );
};

export default ThemeButton;
