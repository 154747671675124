import { Link } from "react-router-dom";
import thankyou_failed from "../../../assests/tipperUiSection/failed.png";
import thankyoulogo from "../../../assests/tipperUiSection/thankyou-logo.png";
import styles from "./redirectPages.module.scss";
const Error = () => {
  return (
    <>
      <div className={styles.thankyouWrapper}>
        <div className={styles.thankyouHeader}>
          <img src={thankyoulogo} alt="" />
        </div>
        <div className={styles.thankyouContent}>
          <section className={styles.contentContainer}>
            <div className={styles.thankyouLeft}>
              <h3>Payment Setup Failed</h3>
              <p>
                Your stripe setup for payment has failed. Please try again to
                complete your setup for payments.
              </p>
              <p>
                If you have any issues, please don’t hesitate to email us at:
                <br />
                <Link
                  to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  className="text-decoration-none"
                >
                  {" "}
                  <span>support@toptipper.com</span>
                </Link>
              </p>
              <Link
                to={`${process.env.REACT_APP_DASHBOARD_URL}`}
                target="_blank"
              >
                <button className={styles.btn}>Go to My Dashboard</button>
              </Link>
            </div>
            <div className={styles.thankyouRight}>
              <img src={thankyou_failed} alt="" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Error;
